import { defineStore } from "pinia";
import { CustomerSingle, CustomerStatus } from "~/types/customers";
import {
  Statement,
  StatementActivity,
  StatementAuto,
  StatementAutoPayload,
  StatementManualPayload,
  StatementOutstanding,
  StatementStatus,
} from "~/types/statements";
import {
  Meta,
  ReportExtension,
  ReportResponse,
  ResponseType,
  Filters,
  PillVariant,
  Option,
} from "~/types/general";
import { useTasksStore } from "~/store/tasks";
import { FilterType } from "~/types/filters";
import { NotificationTag } from "~/types/notifications";

export const useStatementsStore = defineStore("statements", () => {
  // State
  const statements = ref<Statement[]>([]);
  const meta = ref<Meta>({});

  const viewingActivities = ref<StatementActivity[]>([]);
  const viewingOutstanding = ref<StatementOutstanding[]>([]);
  const viewingCustomer = ref<CustomerSingle>({} as CustomerSingle);

  const selectedActivity = ref<StatementActivity>({} as StatementActivity);
  const selectedOutstanding = ref<StatementOutstanding>(
    {} as StatementOutstanding
  );

  // Auto Statements
  const autoStatements = ref<StatementAuto[]>([]);
  const viewingStatementAuto = ref<StatementAuto>({} as StatementAuto);

  const isOpenSidebar = ref(false);
  const editMode = ref(false);

  const pageTable = ref(1);
  const perPageTable = ref(10);

  const selectedStatements = ref<number[]>([]);
  const templateMergeTags = ref(["User Name", "Statement", "Due Date"]);

  // Filters
  const filters = ref<Filters>({
    statuses: {
      type: FilterType.DEFAULT,
      title: "Status",
      options: [
        {
          name: "Active",
          value: StatementStatus.ACTIVE,
          type: PillVariant.GREEN,
        },
        {
          name: "Awaiting Payment",
          value: StatementStatus.AWAITING,
          type: PillVariant.GREY,
        },
        {
          name: "Overdue",
          value: StatementStatus.OVERDUE,
          type: PillVariant.RED,
        },
      ],
      chosenOptions: [],
    },
    customerStatus: {
      type: FilterType.DEFAULT,
      title: "Customer Status",
      options: [
        {
          name: "Active",
          value: CustomerStatus.ACTIVE,
          type: PillVariant.GREEN,
        },
        {
          name: "Application",
          value: CustomerStatus.APPLICATION,
          type: PillVariant.GREEN,
        },
        {
          name: "Pending",
          value: CustomerStatus.PENDING,
          type: PillVariant.GREY,
        },
        {
          name: "Declined",
          value: CustomerStatus.BLOCKED,
          type: PillVariant.RED,
        },
        {
          name: "Archive",
          value: CustomerStatus.ARCHIVE,
          type: PillVariant.GREY,
        },
      ],
      chosenOptions: [],
    },
    customers: {
      type: FilterType.DEFAULT,
      title: "Customers",
      options: [],
      chosenOptions: [],
    },
    creditService: {
      type: FilterType.SWITCHER,
      title: "Credit Service",
      options: [],
      chosenOptions: [],
      toggle: false,
      isSingle: true,
    },
    target_date: {
      type: FilterType.DATE_RANGE,
      title: "Target Date",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    search: {
      type: FilterType.SEARCH,
      title: "Search",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
  });

  const mergeTagsList = ref([
    { name: "User Email", value: NotificationTag.USER_EMAIL },
    { name: "User Name", value: NotificationTag.USER_NAME },
    { name: "Customer", value: NotificationTag.CUSTOMER },
    { name: "Statement", value: NotificationTag.STATEMENT },
    { name: "Period", value: NotificationTag.PERIOD },
    {
      name: "Outstanding Statement",
      value: NotificationTag.OUTSTANDING_STATEMENT,
    },
    {
      name: "Activities Statement",
      value: NotificationTag.ACTIVITIES_STATEMENT,
    },
    // { name: "Onboard Link", value: NotificationTag.ONBOARD_LINK },
  ]);

  // Getters
  const customerFilterOptions = computed((): Option[] => {
    const customers = statements.value?.map((statement) => ({
      name: statement.customer?.name,
      value: statement.customer?.id,
    }));

    return [
      ...new Map(customers.map((item) => [item.value, item])).values(),
    ].filter((customer) => customer.name);
  });

  // Actions
  async function getStatements(params?): Promise<ResponseType<Statement[]>> {
    try {
      const response = await useVaniloApi("/statements", { params });
      return response as ResponseType<Statement[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getStatementsActivities(
    id: number,
    params?
  ): Promise<ResponseType<StatementActivity[]>> {
    try {
      const response = await useVaniloApi(`/statements/${id}/activities`, {
        params,
      });
      return response as ResponseType<StatementActivity[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getStatementsOutstanding(
    id: number,
    params?
  ): Promise<ResponseType<StatementOutstanding[]>> {
    try {
      const response = await useVaniloApi(`/statements/${id}/outstanding`, {
        params,
      });
      return response as ResponseType<StatementOutstanding[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function generateManualStatement(
    body?: StatementManualPayload
  ): Promise<ResponseType<Statement[]>> {
    try {
      const response = await useVaniloApi("/statements/generate", {
        method: "POST",
        body,
      });
      return response as ResponseType<Statement[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getAutoStatements(
    params?
  ): Promise<ResponseType<StatementAuto[]>> {
    try {
      const response = await useVaniloApi("/statements/templates", { params });
      return response as ResponseType<StatementAuto[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getAutoSingleStatement(
    id: number
  ): Promise<ResponseType<StatementAuto>> {
    try {
      const response = await useVaniloApi(`/statements/templates/${id}`);
      return response as ResponseType<StatementAuto>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createAutoStatement(
    body?: StatementAutoPayload
  ): Promise<ResponseType<StatementAuto>> {
    try {
      const response = await useVaniloApi("/statements/templates", {
        method: "POST",
        body,
      });
      return response as ResponseType<StatementAuto>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateAutoStatement(
    id?: number,
    body?: StatementAutoPayload
  ): Promise<ResponseType<StatementAuto>> {
    try {
      const response = await useVaniloApi(`/statements/templates/${id}`, {
        method: "POST",
        body,
      });
      return response as ResponseType<StatementAuto>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteAutoStatement(id?: number) {
    try {
      const response = await useVaniloApi(`/statements/templates/${id}`, {
        method: "DELETE",
      });
      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function printStatementsActivities(
    id: number,
    body: { format: ReportExtension; date_from?: string; date_to?: string }
  ): Promise<ResponseType<ReportResponse>> {
    try {
      const response = await useVaniloApi(
        `/statements/${id}/activities/report`,
        {
          method: "POST",
          body,
        }
      );
      return response as ResponseType<ReportResponse>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function printStatementsOutstanding(
    id: number,
    body: { format: ReportExtension; date_from?: string; date_to?: string }
  ): Promise<ResponseType<ReportResponse>> {
    try {
      const response = await useVaniloApi(
        `/statements/${id}/outstanding/report`,
        {
          method: "POST",
          body,
        }
      );
      return response as ResponseType<ReportResponse>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function printStatementsPurchaseOutstanding(
    id: number,
    body: { format: ReportExtension; date_from?: string; date_to?: string }
  ): Promise<ResponseType<ReportResponse>> {
    try {
      const response = await useVaniloApi(
        `/statements/${id}/purchase-outstanding/report`,
        {
          method: "POST",
          body,
        }
      );
      return response as ResponseType<ReportResponse>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function sendStatementsActivities(body: {
    customers: number[];
    date_from?: string;
    date_to?: string;
  }) {
    try {
      const response = await useVaniloApi(`/statements/activities/send`, {
        method: "POST",
        body,
      });
      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function sendStatementsActivitiesNew(body: {
    customers: number[];
    date_from?: string;
    date_to?: string;
  }) {
    try {
      const response = await useVaniloApi(`/statements/activities/new-send`, {
        method: "POST",
        body,
      });
      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function sendStatementsOutstanding(body: {
    customers: number[];
    date_from?: string;
    date_to?: string;
  }) {
    try {
      const response = await useVaniloApi(`/statements/outstanding/send`, {
        method: "POST",
        body,
      });
      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function sendStatementsOutstandingNew(body: {
    customers: number[];
    date_from?: string;
    date_to?: string;
  }) {
    try {
      const response = await useVaniloApi(`/statements/outstanding/new-send`, {
        method: "POST",
        body,
      });
      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function sendStatementsPurchaseOutstanding(body: {
    customers: number[];
    date_from?: string;
    date_to?: string;
  }) {
    try {
      const response = await useVaniloApi(
        `/statements/purchase-outstanding/send`,
        {
          method: "POST",
          body,
        }
      );
      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function sendStatementsPurchaseActivities(body: {
    customers: number[];
    date_from?: string;
    date_to?: string;
  }) {
    try {
      const response = await useVaniloApi(
        `/statements/purchase-activities/send`,
        {
          method: "POST",
          body,
        }
      );
      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  function clearFilters() {
    for (let d in filters.value) {
      filters.value[d].chosenOptions = [];
    }
  }

  async function bulkExportStatements(payload) {
    let response: any;
    const tasksStore = useTasksStore();

    useIsLoading(true);
    try {
      response = await useVaniloApi(`/statements/export`, {
        method: "POST",
        body: payload,
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    useIsLoading(false);

    tasksStore.taskId = response.data.id;

    return response;
  }

  async function bulkExportStatementsWithOutstanding(payload) {
    let response: any;
    const tasksStore = useTasksStore();

    useIsLoading(true);
    try {
      response = await useVaniloApi(`/statements/export-with-outstanding-balance`, {
        method: "POST",
        body: payload,
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    useIsLoading(false);

    tasksStore.taskId = response.data.id;

    return response;
  }

  async function sendStatementsPaybackSummary(body: {
    customers: number[];
    date_from?: string;
    date_to?: string;
    ids?: number[];
  }) {
    try {
      const response = await useVaniloApi(`/statements/payback-summary/send`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  return {
    statements,
    meta,
    viewingActivities,
    viewingOutstanding,
    viewingCustomer,
    selectedActivity,
    selectedOutstanding,
    autoStatements,
    viewingStatementAuto,
    isOpenSidebar,
    editMode,
    pageTable,
    perPageTable,
    selectedStatements,
    templateMergeTags,
    filters,
    customerFilterOptions,
    mergeTagsList,
    getStatements,
    getStatementsActivities,
    getStatementsOutstanding,
    generateManualStatement,
    getAutoStatements,
    getAutoSingleStatement,
    createAutoStatement,
    updateAutoStatement,
    deleteAutoStatement,
    printStatementsActivities,
    printStatementsOutstanding,
    printStatementsPurchaseOutstanding,
    sendStatementsActivities,
    sendStatementsOutstanding,
    sendStatementsPurchaseOutstanding,
    sendStatementsPurchaseActivities,
    sendStatementsPaybackSummary,
    clearFilters,
    bulkExportStatements,
    bulkExportStatementsWithOutstanding,
    sendStatementsActivitiesNew,
    sendStatementsOutstandingNew,
  };
});
